<template>
  <article
    v-for="item in items"
    :key="item.id"
    class="accordion"
    :class="{ 'mb-1.5': nested, 'mb-3 px-3 xl:pl-0': !nested }"
    :data-testid="nested ? 'accordion-nested' : 'accordion'"
  >
    <header>
      <component :is="data.elementTag?.name || item.elementTag?.name || 'p'">
        <button
          class="flex w-full items-center overflow-hidden px-3 py-2 text-left text-lg font-semibold uppercase"
          :class="{
            'justify-between bg-accordion-primary text-white': !nested,
            'bg-accordion-secondary text-card-primary': nested
          }"
          data-testid="accordion-toggler"
          @click="toggleItem(item.id)"
        >
          <span
            v-if="nested"
            class="relative mr-3 h-6 w-6 min-w-[24px] rounded bg-card-primary"
            role="presentation"
            data-testid="accordion-plus-icon"
          >
            <!-- plus icon -->
            <span
              role="presentation"
              class="absolute left-1/2 top-1/2 h-1 w-4 -translate-x-1/2 -translate-y-1/2 bg-accordion-secondary"
            ></span>
            <span
              role="presentation"
              class="absolute left-1/2 top-1/2 h-1 w-4 -translate-x-1/2 -translate-y-1/2 rotate-90 bg-accordion-secondary duration-300"
              :class="{
                'scale-x-0': activeItems[item.id]
              }"
            ></span>
          </span>

          <span data-testid="accordion-title">
            {{ item.title ?? '' }}
          </span>

          <Icon
            v-if="!nested"
            class="-my-2 h-6 w-6 text-5xl transition-transform duration-300"
            data-testid="accordion-arrow-icon"
            name="fa-solid:chevron-down"
            :class="activeItems[item.id] ? 'rotate-180' : ''"
          />
        </button>
      </component>
    </header>

    <Vue3SlideUpDown
      v-model="activeItems[item.id]"
      :duration="250"
      data-testid="accordion-slide-up-down"
    >
      <div v-show="initialized">
        <div v-if="'items' in item" class="mt-3">
          <SectionsAccordion :data="item" :nested="true" />
        </div>

        <main
          v-else-if="item.description"
          class="prose mt-1 max-w-full bg-card-secondary p-3 text-card-primary"
          data-testid="accordion-desc"
        >
          <SectionsRichTextCkeditor
            :data="{ content: item.description }"
            class="px-0"
          />
        </main>
      </div>
    </Vue3SlideUpDown>
  </article>
</template>

<script lang="ts" setup>
import type { StrapiSectionsAccordion } from '@autobid/strapi-integration/typescript/strapi/sections/SectionAccordion'
import type { StrapiSectionsMultiAccordion } from '@autobid/strapi-integration/typescript/strapi/sections/SectionMultiAccordion'
import type { StrapiComponentProps } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

interface Props {
  data:
    | StrapiComponentProps<StrapiSectionsAccordion>
    | StrapiComponentProps<StrapiSectionsMultiAccordion>
  nested?: boolean
}

const props = defineProps<Props>()
const activeItems = ref([])
const initialized = ref(false)
const items = 'items' in props.data ? props.data.items : props.data.accordions

const toggleItem = (id: number) => {
  const result = props.data.alwaysOpen ? [...activeItems.value] : []
  const isActive = !!activeItems.value[id]

  result[id] = !isActive
  activeItems.value = result
}

onMounted(() => {
  initialized.value = true
})
</script>
